import { DeferSeoProps, JsonLd } from 'gatsby-plugin-next-seo'
import { Overrides } from 'gatsby-plugin-next-seo/lib/utils/shared-types'
import React, { FC } from 'react'
import { CollegeOrUniversity as SchemaCollegeOrUniversity, WithContext } from 'schema-dts'

type CollegeOrUniversity = Extract<SchemaCollegeOrUniversity, object>
export interface CollegeOrUniversityJsonLdProps extends DeferSeoProps, Overrides<CollegeOrUniversity> {
  /**
   * Globally unique ID of the specific business location in the form of a URL.
   *
   * @remarks
   *
   * The ID should be stable and unchanging over time. Google Search treats the
   * URL as an opaque string and it does not have to be a working link. If the
   * business has multiple locations, make sure the `id` is unique for each
   * location.
   */
  id: string

  /**
   * The name of the college.
   */
  name: string

  /**
   * An logo in the form of an URL.
   */
  logo: string

  /**
   * The home page URL.
   */
  url: string
}

export const CollegeOrUniversityJsonLd: FC<CollegeOrUniversityJsonLdProps> = ({
  id,
  name,
  logo,
  url,
  overrides = {},
  defer = false,
}) => {
  const json: WithContext<CollegeOrUniversity> = {
    '@context': 'https://schema.org',
    '@type': 'CollegeOrUniversity',
    '@id': id,
    name,
    url,
    logo,
    ...overrides,
  }

  return <JsonLd defer={defer} json={json} />
}
